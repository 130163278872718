var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loadingEnvironment)?_c('v-navigation-drawer',_vm._b({class:("DKNavProfile shadow-sm rounded-tr-lg rounded-br-lg " + (_vm.getThemeMode.verticalSidebarMini &&
    'v-navigation-drawer-dk animated animate__bounce')),attrs:{"color":_vm.$vuetify.theme.dark ? 'dark' : _vm.getThemeMode.verticalSidebarDrawerColor,"expand-on-hover":_vm.getThemeMode.verticalSidebarMini,"mini-variant":_vm.getThemeMode.verticalSidebarMini,"src":_vm.bg,"dark":_vm.getThemeMode.verticalSidebarDrawerColor != 'white' ? true : false,"app":"","id":"vNavigationDk","disable-resize-watcher":false,"mobile-breakpoint":1350,"height":"100%","width":"320px","floating":true,"right":_vm.$vuetify.rtl,"permanent":""},scopedSlots:_vm._u([{key:"img",fn:function(props){return [_c('v-img',_vm._b({staticClass:"test",style:({ opacity: _vm.opacity })},'v-img',props,false))]}}],null,false,2423834502),model:{value:(_vm.getThemeMode.verticalSidebarDrawer),callback:function ($$v) {_vm.$set(_vm.getThemeMode, "verticalSidebarDrawer", $$v)},expression:"getThemeMode.verticalSidebarDrawer"}},'v-navigation-drawer',_vm.$attrs,false),[_c('vue-perfect-scrollbar',{staticClass:"h-100 rtl-ps-none ps scroll",staticStyle:{"height":"100%"},attrs:{"settings":{ suppressScrollX: true, wheelPropagation: false }}},[_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-avatar',[(_vm.getThemeMode.verticalSidebarDrawerColor == 'dark')?_c('v-img',{attrs:{"src":require("@/assets/images/arctic-circle-logo.svg")}}):_c('v-img',{attrs:{"src":require("@/assets/images/logo.svg")}})],1),_c('v-list-item-title',{staticClass:"text-18 text-uppercase text-default"},[_vm._v("Silver")]),_c('v-scroll-x-transition')],1)],1),(_vm.silver && _vm.silver.menu && _vm.silver.menu.showDoctorPanel)?_c('div',{staticClass:"text-center cardiology"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('div',{staticClass:"px-4"},[(_vm.showMiniMenu)?_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-menu")])],1):_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!!_vm.callData}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.serviceSelected || "Servicio")+" "),_c('i',{staticClass:"mdi mdi-chevron-down"})])],1)]}}],null,false,2543207446)},[_c('v-list',{attrs:{"max-height":"400"}},_vm._l((_vm.servicesOrdered),function(item,index){return _c('v-list-item',{key:index,staticClass:"dk-heigth"},[_c('v-list-item-title',{staticStyle:{"font-size":"0.9rem","cursor":"pointer"},on:{"click":function () { return _vm.handleChangeService(item); }}},[_c('i',{staticClass:"mdi mdi-circle-medium"}),_vm._v(" "+_vm._s(item.name))])],1)}),1)],1)],1):_vm._e(),(
        _vm.profileDataMenu &&
        _vm.profileDataMenu.length &&
        _vm.silver &&
        _vm.silver.menu &&
        _vm.silver.menu.showWorkStations
      )?_c('v-list',_vm._l((_vm.computedItems),function(item){return _c('v-list-group',{key:item.title,staticStyle:{"color":"gray !important"},attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',{class:_vm.$route.path.includes('workstation') && 'ActiveLink',domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{class:_vm.$route.path.includes('workstation') && 'ActiveLink',staticStyle:{"font-size":"0.9rem"},domProps:{"textContent":_vm._s(item.title)}})],1)]},proxy:true}],null,true),model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},_vm._l((item.children),function(child){return _c('v-list-item',{key:child.title,staticStyle:{"padding-left":"0.5rem","padding-bottom":"0"}},[_c('v-list-item-content',{staticStyle:{"font-size":"1rem","padding":"0"},on:{"click":function () { return _vm.handleChangeRoute(child.to); }}},[_c('a',{class:_vm.$route.path.includes(child.to)
                  ? 'text-18 pl-6 v-list-item-active v-list-item--link theme--light'
                  : 'text-18 pl-6 v-list-item v-list-item--link theme--light',attrs:{"exact-path":""}},[_c('v-list-item-title',{staticStyle:{"font-size":"0.9rem"},domProps:{"textContent":_vm._s(_vm.meValidation(child) || child.title)}})],1)])],1)}),1)}),1):(_vm.silver && _vm.silver.menu && _vm.silver.menu.showWorkStations)?_c('div',[_c('p',{staticStyle:{"margin":"1rem"}},[_vm._v("No hay puestos de trabajo")])]):_vm._e(),(
        _vm.permissionsMenu &&
        _vm.permissionsMenu.length > 0 &&
        _vm.silver &&
        _vm.silver.menu &&
        _vm.silver.menu.showRecorders
      )?_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{class:_vm.$route.path.includes('recorders') && 'ActiveLink',domProps:{"textContent":_vm._s('mdi-file-video')}})],1),_c('v-list-item-content',{on:{"click":_vm.goRecorders}},[_c('v-list-item-title',{class:_vm.$route.path.includes('recorders') && 'ActiveLink',domProps:{"textContent":_vm._s('Grabaciones')}})],1)],1)],1)],1):_vm._e(),(_vm.silver && _vm.silver.menu && _vm.silver.menu.showAppointments)?_c('v-list',{staticClass:"Appointments"},[_c('v-list-item-group',{staticStyle:{"color":"gray !important"},attrs:{"no-action":""}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{class:_vm.$route.path.includes('appointments') && 'ActiveLink',domProps:{"textContent":_vm._s('mdi-view-agenda')}})],1),_c('v-list-item-content',{on:{"click":_vm.goAppointments}},[_c('v-list-item-title',{class:_vm.$route.path.includes('appointments') && 'ActiveLink',domProps:{"textContent":_vm._s('Citas')}})],1)],1)],1)],1):_vm._e(),(
        _vm.profileDataMenu &&
        _vm.profileDataMenu.length &&
        _vm.silver &&
        _vm.silver.menu &&
        _vm.silver.menu.showDoctorPanel
      )?_c('v-list',_vm._l((_vm.computedItemsDoctorPanel),function(item){return _c('v-list-group',{key:item.title,staticStyle:{"color":"gray !important"},attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',{class:_vm.$route.path.includes('doctorPanel') && 'ActiveLink',domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{class:_vm.$route.path.includes('doctorPanel') && 'ActiveLink',staticStyle:{"font-size":"0.9rem"},domProps:{"textContent":_vm._s(item.title)}})],1)]},proxy:true}],null,true),model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},_vm._l((item.children),function(child){return _c('v-list-item',{key:child.title,staticStyle:{"padding-left":"0.5rem","padding-bottom":"0"}},[_c('v-list-item-content',{staticStyle:{"font-size":"1rem","padding":"0"},on:{"click":function () { return _vm.handleChangeRouteDoctorPanel(child.to); }}},[_c('a',{class:_vm.$route.path.includes(child.to)
                  ? 'text-18 pl-6 v-list-item-active v-list-item--link theme--light'
                  : 'text-18 pl-6 v-list-item v-list-item--link theme--light',attrs:{"exact-path":""}},[_c('v-list-item-title',{staticStyle:{"font-size":"0.9rem"},domProps:{"textContent":_vm._s(_vm.meValidation(child) || child.title)}})],1)])],1)}),1)}),1):(_vm.silver && _vm.silver.menu && _vm.silver.menu.showDoctorPanel)?_c('div',[_c('p',{staticStyle:{"margin":"1rem"}},[_vm._v("No hay puestos de trabajo")])]):_vm._e(),_c('p',{staticStyle:{"visibility":"hidden"}},[_vm._v("HoalsaksalkslakslHoalsaksalkslakslaksajk")])],1),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}},[_c('div',{staticStyle:{"background":"#fffd","padding":"1rem"}},[_c('h4',[_vm._v(" "+_vm._s(_vm.textDialog)+" ")]),_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-btn',{on:{"click":function($event){_vm.showAlert = false}}},[_vm._v("OK")])],1)])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }