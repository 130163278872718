<template>
  <v-navigation-drawer
    v-model="getThemeMode.verticalSidebarDrawer"
    :color="
      $vuetify.theme.dark ? 'dark' : getThemeMode.verticalSidebarDrawerColor
    "
    :expand-on-hover="getThemeMode.verticalSidebarMini"
    :mini-variant="getThemeMode.verticalSidebarMini"
    :src="bg"
    :dark="getThemeMode.verticalSidebarDrawerColor != 'white' ? true : false"
    app
    id="vNavigationDk"
    :disable-resize-watcher="false"
    :mobile-breakpoint="1350"
    height="100%"
    width="320px"
    :class="`DKNavProfile shadow-sm rounded-tr-lg rounded-br-lg ${
      getThemeMode.verticalSidebarMini &&
      'v-navigation-drawer-dk animated animate__bounce'
    }`"
    :floating="true"
    :right="$vuetify.rtl"
    v-bind="$attrs"
    v-if="!loadingEnvironment"
    permanent
  >
    <template v-slot:img="props">
      <v-img :style="{ opacity: opacity }" v-bind="props" class="test" />
    </template>
    <vue-perfect-scrollbar
      :settings="{ suppressScrollX: true, wheelPropagation: false }"
      class="h-100 rtl-ps-none ps scroll"
      style="height: 100%"
    >
      <v-list dense nav>
        <v-list-item class="px-0">
          <v-list-item-avatar>
            <v-img
              v-if="getThemeMode.verticalSidebarDrawerColor == 'dark'"
              src="@/assets/images/arctic-circle-logo.svg"
            />
            <v-img v-else src="@/assets/images/logo.svg" />
          </v-list-item-avatar>

          <v-list-item-title class="text-18 text-uppercase text-default"
            >Silver</v-list-item-title
          >
          <v-scroll-x-transition>
            <!-- <v-btn icon @click.stop="toggleSidebar">
            <v-tooltip
              v-if="!getThemeMode.verticalSidebarMini"
              right
              color="primary"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon dark v-bind="attrs" v-on="on" color
                  >mdi-circle-slice-8</v-icon
                >
              </template>
              <span>Desanclar</span>
            </v-tooltip>
            <v-tooltip
                v-if="getThemeMode.verticalSidebarMini"
                right
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon dark v-bind="attrs" v-on="on" color
                    >mdi-circle-outline</v-icon
                  >
                </template>
                <span>Anclar</span>
              </v-tooltip>
            </v-btn> -->
          </v-scroll-x-transition>
        </v-list-item>
      </v-list>

      <!-- <v-list v-if="profileDataMenu && profileDataMenu.length">
        <template v-for="(item, i) in computedItems">
          <div
            :key="item.subheader"
            v-if="item.subheader"
            :class="miniVariant && 'px-1'"
            id="dk_list"
          >
            <v-subheader>
              {{ item.subheader }}
            </v-subheader>

            <v-divider></v-divider>
          </div>

          <base-item-group
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
            @click="handleChangeProfile"
            :disabled="!!!callData"
            :class="!!!callData ? 'ListProfile' : 'ListProfile Disabled'"
          >
            -->
      <!-- </base-item-group>

          <base-item
            v-else-if="!item.subheader && !item.children"
            :key="`item-${i}`"
            :item="item"
          /> -->
      <!-- <v-list-item
              v-for="(itemChildren, index) in item.children"
              :key="index"
            >
              {{ itemChildren.title }}
            </v-list-item> -->
      <!-- </template> -->
      <!-- </v-list> -->

      <div
        class="text-center cardiology"
        v-if="silver && silver.menu && silver.menu.showDoctorPanel"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div class="px-4">
              <v-list-item-icon v-if="showMiniMenu">
                <v-icon>mdi-menu</v-icon>
              </v-list-item-icon>
              <v-btn
                v-else
                v-bind="attrs"
                v-on="on"
                :disabled="!!callData"
                class=""
              >
                {{ serviceSelected || "Servicio" }}
                <i class="mdi mdi-chevron-down"></i>
              </v-btn>
            </div>
          </template>
          <v-list max-height="400">
            <v-list-item
              class="dk-heigth"
              v-for="(item, index) in servicesOrdered"
              :key="index"
            >
              <v-list-item-title
                style="font-size: 0.9rem; cursor: pointer"
                @click="() => handleChangeService(item)"
                ><i class="mdi mdi-circle-medium"></i>
                {{ item.name }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-list
        v-if="
          profileDataMenu &&
          profileDataMenu.length &&
          silver &&
          silver.menu &&
          silver.menu.showWorkStations
        "
      >
        <v-list-group
          v-for="item in computedItems"
          :key="item.title"
          v-model="item.active"
          no-action
          style="color: gray !important"
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon
                :class="$route.path.includes('workstation') && 'ActiveLink'"
                v-text="item.icon"
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="$route.path.includes('workstation') && 'ActiveLink'"
                v-text="item.title"
                style="font-size: 0.9rem"
              ></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.children"
            :key="child.title"
            style="padding-left: 0.5rem; padding-bottom: 0"
          >
            <v-list-item-content
              style="font-size: 1rem; padding: 0"
              @click="() => handleChangeRoute(child.to)"
            >
              <a
                :class="
                  $route.path.includes(child.to)
                    ? 'text-18 pl-6 v-list-item-active v-list-item--link theme--light'
                    : 'text-18 pl-6 v-list-item v-list-item--link theme--light'
                "
                exact-path
                ><v-list-item-title
                  v-text="meValidation(child) || child.title"
                  style="font-size: 0.9rem"
                ></v-list-item-title
              ></a>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <div v-else-if="silver && silver.menu && silver.menu.showWorkStations">
        <p style="margin: 1rem">No hay puestos de trabajo</p>
      </div>

      <v-list
        v-if="
          permissionsMenu &&
          permissionsMenu.length > 0 &&
          silver &&
          silver.menu &&
          silver.menu.showRecorders
        "
      >
        <v-list-item-group color="primary">
          <v-list-item>
            <v-list-item-icon>
              <v-icon
                v-text="'mdi-file-video'"
                :class="$route.path.includes('recorders') && 'ActiveLink'"
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="goRecorders">
              <v-list-item-title
                :class="$route.path.includes('recorders') && 'ActiveLink'"
                v-text="'Grabaciones'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-list
        class="Appointments"
        v-if="silver && silver.menu && silver.menu.showAppointments"
      >
        <v-list-item-group style="color: gray !important" no-action>
          <v-list-item>
            <v-list-item-icon>
              <v-icon
                v-text="'mdi-view-agenda'"
                :class="$route.path.includes('appointments') && 'ActiveLink'"
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="goAppointments">
              <v-list-item-title
                v-text="'Citas'"
                :class="$route.path.includes('appointments') && 'ActiveLink'"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-list
        v-if="
          profileDataMenu &&
          profileDataMenu.length &&
          silver &&
          silver.menu &&
          silver.menu.showDoctorPanel
        "
      >
        <v-list-group
          v-for="item in computedItemsDoctorPanel"
          :key="item.title"
          v-model="item.active"
          no-action
          style="color: gray !important"
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon
                :class="$route.path.includes('doctorPanel') && 'ActiveLink'"
                v-text="item.icon"
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="item.title"
                style="font-size: 0.9rem"
                :class="$route.path.includes('doctorPanel') && 'ActiveLink'"
              ></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.children"
            :key="child.title"
            style="padding-left: 0.5rem; padding-bottom: 0"
          >
            <v-list-item-content
              style="font-size: 1rem; padding: 0"
              @click="() => handleChangeRouteDoctorPanel(child.to)"
            >
              <a
                :class="
                  $route.path.includes(child.to)
                    ? 'text-18 pl-6 v-list-item-active v-list-item--link theme--light'
                    : 'text-18 pl-6 v-list-item v-list-item--link theme--light'
                "
                exact-path
                ><v-list-item-title
                  v-text="meValidation(child) || child.title"
                  style="font-size: 0.9rem"
                ></v-list-item-title
              ></a>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <div v-else-if="silver && silver.menu && silver.menu.showDoctorPanel">
        <p style="margin: 1rem">No hay puestos de trabajo</p>
      </div>
      <p style="visibility: hidden">HoalsaksalkslakslHoalsaksalkslakslaksajk</p>
    </vue-perfect-scrollbar>

    <v-dialog v-model="showAlert" max-width="400">
      <div style="background: #fffd; padding: 1rem">
        <h4>
          {{ textDialog }}
        </h4>
        <div style="display: flex; justify-content: center">
          <v-btn @click="showAlert = false">OK</v-btn>
        </div>
      </div>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
// import { items } from "@/data/navigation";
import moment from "moment";

export default {
  // components: {
  //   VuePerfectScrollbar,
  // },
  name: "ProfileOptions",

  props: ["callDataProp"],
  data() {
    return {
      drawer: true,
      showAlert: false,
      timer3: null,
      textDialog: "",
      showMiniMenu: false,
      switch1: "on",
      color: "dark",
      colors: ["primary", "blue", "success", "red", "teal"],
      right: false,
      permanent: true,
      miniVariant: false,
      expandOnHover: true,
      background: false,
      opacity: 0.4,
      items: [
        {
          action: "mdi-ticket",
          items: [{ title: "List Item" }],
          title: "Attractions",
        },
        {
          action: "mdi-silverware-fork-knife",
          active: true,
          items: [
            { title: "Breakfast & brunch" },
            { title: "New American" },
            { title: "Sushi" },
          ],
          title: "Dining",
        },
        {
          action: "mdi-school",
          items: [{ title: "List Item" }],
          title: "Education",
        },
        {
          action: "mdi-run",
          items: [{ title: "List Item" }],
          title: "Family",
        },
        {
          action: "mdi-bottle-tonic-plus",
          items: [{ title: "List Item" }],
          title: "Health",
        },
        {
          action: "mdi-content-cut",
          items: [{ title: "List Item" }],
          title: "Office",
        },
        {
          action: "mdi-tag",
          items: [{ title: "List Item" }],
          title: "Promotions",
        },
      ],
      itemsMenu: [
        {
          title: "Puestos de atención",
          icon: "mdi-book-open",
          group: "pages",
          children: [],
        },
      ],
      itemsDoctorPanel: [
        {
          title: "Panel Doctor",
          icon: "mdi-book-open",
          group: "pages",
          children: [],
        },
      ],
      serviceSelected: "Servicio",
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
    };
  },
  mounted() {
    this.activeLink();

    if (this.$route.path === "/home/") {
      this.toggleSidebar();
    } else {
      this.showMiniMenu = true;
      if (this.silver && this.silver.menu && this.silver.menu.showDoctorPanel) {
        this.changeVerticalSidebarMini(false);
        this.expandOnHover = false;
      } else {
        this.expandOnHover = true;
        this.changeVerticalSidebarMini(true);
      }
    }

    let service = this.$route.query?.service;
    if (service)
      this.serviceSelected = this.services.find((s) => s.code == service)?.name;
    this.setDoctorService(service);
  },
  // mounted() {
  //   const timer = setTimeout(
  //     () => {
  //       this.$watch(
  //         function () {
  //           const $navBar = this.$el.querySelector(".v-list-item--link");
  //           console.log($navBar);
  //           return $navBar?.click();
  //         },
  //         function (oldVal, newVal) {
  //           console.log(oldVal, " - ", newVal);
  //           return "";
  //         }
  //       );
  //       clearTimeout(timer);
  //     },
  //     navigator.connection.downlink >= 7.5 ? 1000 : 5000
  //   );
  // },
  computed: {
    ...mapGetters(["getThemeMode"]),
    ...mapState({
      branches: (state) => state.branchesSilver.branchesData,
      profileData: (state) => state.profileSilver.profileData,
      selectedBranch: (state) => state.branchesSilver.selectedBranch,
      callData: (state) => state.loggedUserSilver.callData,
      branchesInfoCompany: (state) => state.profileSilver.branchesInfoCompany,
      silver: (state) => state.environmentSilver.environment.silver,
      loadingEnvironment: (state) => state.environmentSilver.loading,
      services: (state) => state.doctorPanel.services,
    }),
    profileDataMenu() {
      return this.profileData?.menu?.filter((menuItem) =>
        menuItem.attribute.url.includes("workstation")
      );
    },
    servicesOrdered() {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return this.services ? this.services.sort(compare) : [];
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.services.sort(compare);
    },
    findMe() {
      const find = this.itemsMenu.map((item) => {
        return item.children.find((childrenItem) => {
          return (
            childrenItem.actualUser?.uUser.id === this.profileData.user?.id
          );
        });
      });
      return find?.[0] || undefined;
    },
    permissionsMenu() {
      return this.profileData?.menu?.filter((menuItem) => {
        return menuItem.attribute.groupLabel.includes("Panel de compañia") || menuItem.attribute.groupLabel.includes("companyPanel");
      }
        
      );
    },
    itemsMenuFilter() {
      return this.profileDataMenu.map((childrenItem) => {
        const id =
          +childrenItem.attribute?.url.split("/")[
            childrenItem.attribute?.url.split("/").length - 1
          ];
        return this.branchesInfoCompany.find((branch) => {
          return branch.id === id;
        })
          ? {
              url: childrenItem.attribute?.url,
              ...this.branchesInfoCompany.find((branch) => {
                return branch.id === id;
              }),
            }
          : childrenItem;
      });
    },
    // userData() {
    //   return `${this.profileData.user?.firstName || ""} ${
    //     this.profileData.user?.lastName || ""
    //   }`;
    // },
    bg() {
      return this.background
        ? "https://images.pexels.com/photos/1687678/pexels-photo-1687678.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
        : undefined;
    },
    getMiniVariant() {
      return this.miniVariant;
    },
    computedItems() {
      return this.itemsMenu.map((item, index) => this.mapItem(item, index));
    },
    computedItemsDoctorPanel() {
      // Check Service Selected
      let service = this.$route.query?.service;
      if (service)
        return this.itemsDoctorPanel.map((item, index) =>
          this.mapItemDoctor(item, index)
        );
      else return [];
    },
  },
  watch: {
    loadingEnvironment(val) {
      if (
        !val &&
        this.silver &&
        this.silver.menu &&
        this.silver.menu.showDoctorPanel
      ) {
        if (this.$route.path !== "/home/") {
          this.onChange();
        }
      }
    },
    branches(val) {
      if (!val) return;
      this.getProfileHandler();
    },
    profileData(val) {
      if (!val) return;
      this.itemsMenu[0].children = this.itemsMenuFilter;
      // this.itemsMenu2[0].children = profileMenu;
    },
    selectedBranch(val) {
      if (!val) {
        return;
      }
      // this.itemsMenu = this.findMe;
      this.getProfileHandler();
    },
    "$route.path"(val) {
      this.activeLink();
      // const timer = setTimeout(() => {
      //   document.querySelector(".v-main").style.marginLeft = "56px";
      //   clearTimeout(timer);
      // }, 1000);

      if (val === "/home/") {
        this.toggleSidebar();
        if (
          this.silver &&
          this.silver.menu &&
          this.silver.menu.showDoctorPanel
        ) {
          this.showMiniMenu = false;
          const timer = setTimeout(() => {
            document
              .getElementById("vNavigationDk")
              .removeEventListener("mouseover", this.trueMouse);
            document
              .getElementById("vNavigationDk")
              .removeEventListener("mouseleave", this.falseMouse);
            clearTimeout(timer);
          }, 400);
        }
      } else {
        if (
          this.silver &&
          this.silver.menu &&
          this.silver.menu.showDoctorPanel
        ) {
          this.showMiniMenu = true;
          this.onChange();
        }
        this.changeVerticalSidebarMini(true);
        // if (
        //   this.silver &&
        //   this.silver.menu &&
        //   this.silver.menu.showDoctorPanel
        // ) {
        //   this.changeVerticalSidebarMini(false);
        //   this.expandOnHover = false;
        // } else {
        //   this.changeVerticalSidebarMini(true);
        // }
      }
    },
    // silver(val) {
    //   if (val && val.menu && val.menu.showDoctorPanel) {
    //     this.changeVerticalSidebarMini(false);
    //     this.expandOnHover = false;
    //   }
    // },
  },
  methods: {
    ...mapActions([
      "changeVerticalSidebarDrawer",
      "changeVerticalSidebarMini",
      "profileGetActionById",
      "getCompanyBranchesInfo",
      "signOut",
      "setDoctorService",
      "onChangeBody",
      "onChangeTurns",
      "changeThemeLoadingState",
      "setLoggedUserPanel",
      "setLoadingMainTurns",
    ]),
    toggleSidebar() {
      this.changeVerticalSidebarMini(false);
      this.expandOnHover = true;
      // this.$emit("update:mini-variant");
      // console.log("check");
    },
    trueMouse() {
      this.showMiniMenu = false;
    },
    falseMouse() {
      this.showMiniMenu = true;
    },
    onChange() {
      const timer = setTimeout(() => {
        document
          .getElementById("vNavigationDk")
          .addEventListener("mouseover", this.trueMouse);
        document
          .getElementById("vNavigationDk")
          .addEventListener("mouseleave", this.falseMouse);
        clearTimeout(timer);
      }, 400);
    },
    activeLink() {
      // this.timer3 = setTimeout(() => {
      //   if (this.$route.path.includes("appointments")) {
      //     document.querySelector(".Appointments").classList.add("ActiveLink");
      //   } else {
      //     document
      //       .querySelector(".Appointments")
      //       .classList.remove("ActiveLink");
      //   }
      //   clearTimeout(this.timer3);
      // }, 3000);
    },
    meValidation(child) {
      let response;
      if (this.$route.path.includes("/home/workstation/")) {
        response = undefined;
        // this.itemsMenu = ;
      } else {
        if (this.findMe) {
          response =
            child.idUser === this.findMe.actualUser.uUser.id
              ? child.title?.split("- (")[0]
              : false;
        } else {
          response = undefined;
        }
      }

      return response;
    },
    handleChangeRoute(to) {
      if (!this.$root.enableChangeWorkStation) {
        (this.textDialog = "Se esta asignando una llamada automaticamente"),
          (this.showAlert = true);
        return;
      }

      if (this.callDataProp || this.callData) {
        (this.textDialog =
          "Debe finalizar primero el turno que está atendiendo para cambiar de puesto de trabajo"),
          (this.showAlert = true);
        return;
      }
      try {
        this.$router.push({ path: "/home/workstation/" + to });
      } catch (e) {
        console.log(e);
      }
    },
    handleChangeRouteDoctorPanel(to) {
      if (this.callDataProp || this.callData) {
        (this.textDialog =
          "Debe finalizar primero el turno que está atendiendo para cambiar de puesto de trabajo"),
          (this.showAlert = true);
        return;
      }
      try {
        this.$router.push({
          path: "/home/doctorPanel/" + to,
          query: this.$route.query,
        });

        this.onChangeBody([]);
        this.onChangeTurns([]);
        this.setLoggedUserPanel({});

        this.setLoadingMainTurns(true);
      } catch (e) {
        console.log(e);
      }
      this.changeThemeLoadingState(true);
    },
    mapItem(item, index) {
      if (index == 0)
        item.children =
          this.itemsMenuFilter.length && this.itemsMenuFilter[0] !== 0
            ? this.itemsMenuFilter
            : this.profileDataMenu;
      return {
        ...item,
        active: true,
        children: item.children
          ? item.children
              .map((childrenItem) => {
                // console.log(childrenItem.url.slice(1) || childrenItem.attribute.url.slice(1).split("/")[1]);
                return {
                  title:
                    childrenItem.attribute?.label ||
                    `${childrenItem.name} ${
                      childrenItem.actualUser
                        ? `- (${childrenItem.actualUser?.uUser.firstName} ${
                            childrenItem.actualUser?.uUser?.lastName || ""
                          })`
                        : ""
                    }`,
                  to:
                    childrenItem.attribute?.url.split("/")[
                      childrenItem.attribute?.url.split("/").length - 1
                    ] ||
                    childrenItem?.url.split("/")[
                      childrenItem?.url.split("/").length - 1
                    ],
                  idUser: childrenItem.actualUser?.uUser?.id,
                };
              })
              .sort((a, b) =>
                a.title.toLowerCase() > b.title.toLowerCase()
                  ? 1
                  : a.title.toLowerCase() === b.title.toLowerCase()
                  ? 0
                  : -1
              )
          : undefined,
        title: item.title || "Puestos de Atención",
        icon: item.icon || "mdi-book-open",
        group: item.group || "pages", // posible change route
      };
    },
    mapItemDoctor(item, index) {
      let service = this.$route.query?.service;
      let prefix = this.services.find((s) => s.code == service).prefix;
      if (index == 0)
        item.children =
          this.itemsMenuFilter.length && this.itemsMenuFilter[0] !== 0
            ? this.itemsMenuFilter
            : this.profileDataMenu;
      return {
        ...item,
        active: true,
        children: item.children
          ? item.children
              .map((childrenItem) => {
                // console.log(childrenItem.url.slice(1) || childrenItem.attribute.url.slice(1).split("/")[1]);
                return {
                  title:
                    childrenItem.attribute?.label ||
                    `${childrenItem.name} ${
                      childrenItem.actualUser
                        ? `- (${childrenItem.actualUser?.uUser.firstName} ${
                            childrenItem.actualUser?.uUser?.lastName || ""
                          })`
                        : ""
                    }`,
                  to:
                    childrenItem.attribute?.url.split("/")[
                      childrenItem.attribute?.url.split("/").length - 1
                    ] ||
                    childrenItem?.url.split("/")[
                      childrenItem?.url.split("/").length - 1
                    ],
                  idUser: childrenItem.actualUser?.uUser?.id,
                };
              })
              .filter((i) => i.title.toLowerCase().includes(prefix.toLowerCase()))
              .sort((a, b) =>
                a.title.toLowerCase() > b.title.toLowerCase()
                  ? 1
                  : a.title.toLowerCase() === b.title.toLowerCase()
                  ? 0
                  : -1
              )
          : undefined,
        title: item.title || "Puestos de Atención",
        icon: item.icon || "mdi-book-open",
        group: item.group || "pages", // posible change route
      };
    },
    handlerSignOut() {
      this.signOut();
      this.$router.push({ path: "/sessions/login" });
    },
    handleChangeProfile() {
      console.log(true);
    },
    async getProfileHandler() {
      if (this.selectedBranch) {
        await this.getCompanyBranchesInfo(this.selectedBranch.id);
        await this.profileGetActionById(this.selectedBranch.id);
      } else {
        let branch = this.branches.defaultBranch
          ? this.branches.defaultBranch.id
          : this.branches[0].id;
        if (!branch) {
          let inter = setInterval(async () => {
            branch = this.branches.defaultBranch
              ? this.branches.defaultBranch.id
              : this.branches[0].id;
            if (branch) {
              clearInterval(inter);
              await this.getCompanyBranchesInfo(branch);
              await this.profileGetActionById(branch);
            }
          }, 1000);
        } else {
          await this.getCompanyBranchesInfo(branch);
          await this.profileGetActionById(branch);
        }
      }
    },
    goRecorders() {
      if (!this.$root.enableChangeWorkStation) {
        (this.textDialog = "Se esta asignando una llamada automaticamente"),
          (this.showAlert = true);
        return;
      }

      if (this.callDataProp || this.callData) {
        (this.textDialog =
          "Debe finalizar primero el turno que está atendiendo para cambiar de puesto de trabajo"),
          (this.showAlert = true);
        return;
      }

      try {
        if (this.$router.history.current.path != "/home/recorders")
          this.$router.push({ name: "Recorders" });
      } catch (error) {
        console.log(error);
      }
    },

    goAppointments() {
      // if (!this.$root.enableChangeWorkStation) {
      //   (this.textDialog = "Se esta asignando una llamada automaticamente"),
      //     (this.showAlert = true);
      //   return;
      // }

      // if (this.callDataProp || this.callData) {
      //   (this.textDialog =
      //     "Debe finalizar primero el turno que está atendiendo para cambiar de puesto de trabajo"),
      //     (this.showAlert = true);
      //   return;
      // }

      try {
        if (this.$router.history.current.path != "/home/appointments")
          this.$router.push({ name: "Appointments" });
      } catch (error) {
        console.log(error);
      }
    },
    handleChangeService(service) {
      // window.selectedBranch = service;
      // if(this.$route.path.includes("doctorPanel")){
      this.$router.push({ query: { service: service.code } });
      this.serviceSelected = service.name;
      this.setDoctorService(service.code);
      // this.onChangeBody([]);
      // this.onChangeTurns([]);
      this.changeThemeLoadingState(true);

      // }
    },
  },
};
</script>

<style lang="scss">
.ps-container {
  position: inherit !important;
}
.gray {
  color: darkgray;
}
.app-admin-wrap-layout-1.sidebar-mini {
  .v-toolbar {
    left: 56px !important;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .v-main {
    max-width: auto;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    padding-bottom: 0px !important;
  }
  &.sidebar-close {
    .v-toolbar {
      left: 0px !important;
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    }
    .v-main {
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      padding-bottom: 0px !important;
    }
  }
}

.ActiveLink {
  color: var(--v-anchor-base) !important;
}

.v-navigation-drawer-dk {
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

.DKNavProfile {
  z-index: 900008 !important;
}
</style>
